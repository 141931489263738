import { template as template_717bee32db9a4a248a675aa5e57dd859 } from "@ember/template-compiler";
import styles from './features.css';
import type { TOC } from '@ember/component/template-only';
const Features: TOC<{
    Element: HTMLDivElement;
    Blocks: {
        default: [];
    };
}> = template_717bee32db9a4a248a675aa5e57dd859(`
  <div class={{styles.features}} ...attributes>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default Features;
