import { template as template_44b29d3b21c740f09c04a7f0545c11e0 } from "@ember/template-compiler";
import { pageTitle } from 'ember-page-title';
import { Route } from 'ember-polaris-routing';
import CompatRoute from 'ember-polaris-routing/route/compat';
export class ExerciseRootRoute extends Route<object> {
    static{
        template_44b29d3b21c740f09c04a7f0545c11e0(`
    {{pageTitle "Übungen"}}

    {{outlet}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
// @ts-expect-error some broken upstream types here
export default CompatRoute(ExerciseRootRoute);
