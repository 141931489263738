import { template as template_cf1733fdec5c4488bb47b482d243a7af } from "@ember/template-compiler";
import { element } from 'ember-element-helper';
import styles from './content.css';
import type { TOC } from '@ember/component/template-only';
import type { ComponentLike } from '@glint/template';
export interface CardSignature {
    Element: HTMLDivElement;
    Args: {
        element?: ComponentLike<{
            Element: HTMLElement;
        }>;
    };
    Blocks: {
        default: [];
    };
}
const Card: TOC<CardSignature> = template_cf1733fdec5c4488bb47b482d243a7af(`
  {{#let (if @element @element (element "div")) as |Element|}}
    {{! @glint-expect-error https://github.com/typed-ember/glint/issues/610 }}
    <Element class={{styles.card}} ...attributes>
      {{yield}}
    </Element>
  {{/let}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default Card;
