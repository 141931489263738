import { template as template_8c709d6d665c4b4998e7c573473ffd5d } from "@ember/template-compiler";
import { Route } from 'ember-polaris-routing';
import CompatRoute from 'ember-polaris-routing/route/compat';
import { findAssistant } from '../assistants';
import type { Assistant } from '../assistants';
export class AssistantsRoute extends Route<{
    assistant: string;
}> {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    get Assistant() {
        return findAssistant(this.params.assistant as Assistant);
    }
    static{
        template_8c709d6d665c4b4998e7c573473ffd5d(`
    {{#if this.Assistant}}
      {{!@glint-ignore}}
      <this.Assistant />
    {{/if}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
export default CompatRoute(AssistantsRoute);
