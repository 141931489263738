import { template as template_b1a10c6dacf74abf92c577e64914495f } from "@ember/template-compiler";
import styles from './tag.css';
import type { TOC } from '@ember/component/template-only';
interface TagSignature {
    Element: HTMLElement;
    Blocks: {
        default: [];
    };
}
const Tag: TOC<TagSignature> = template_b1a10c6dacf74abf92c577e64914495f(`
  <span class={{styles.tag}} ...attributes>{{yield}}</span>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default Tag;
