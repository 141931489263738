import { template as template_5697e73e57d14a6999a20becdd0b6290 } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { fn } from '@ember/helper';
import { service } from '@ember/service';
import { IconButton, Popover, popover, Tabs, TextInput } from '@hokulea/ember';
import { copyToClipboard, selectWhenFocus } from '../-utils';
import { canUseForTraining, makeTrainingLink } from './domain-objects';
import styles from './styles.css';
import type { RoutineResult } from './domain-objects';
import type { TOC } from '@ember/component/template-only';
import type RouterService from '@ember/routing/router-service';
export class Share extends Component<{
    Args: {
        routine: RoutineResult;
    };
}> {
    @service
    router: RouterService;
    get shareLink() {
        try {
            return window.location.href;
        } catch  {
            return '';
        }
    }
    static{
        template_5697e73e57d14a6999a20becdd0b6290(`
    {{#let (popover position="bottom-start") as |po|}}
      <IconButton
        @icon="share-fat"
        @importance="subtle"
        @spacing="-1"
        @label="Teilen"
        {{po.trigger}}
      />

      <Popover {{po.target}} class={{styles.share}}>
        <Tabs as |tab|>
          <tab.Tab @label="Link">
            <p>Teile den Link zur Kür-Analyse:</p>
            <div>
              <TextInput @value={{this.shareLink}} readonly {{selectWhenFocus}} />
              <IconButton
                @icon="clipboard-text"
                @importance="subtle"
                @spacing="-1"
                @label="Kopieren"
                @push={{fn copyToClipboard this.shareLink}}
              />
            </div>
          </tab.Tab>

          {{#if (canUseForTraining @routine)}}
            {{#let (makeTrainingLink @routine this.router) as |trainingLink|}}
              <tab.Tab @label="Jury Training">
                <p>Teile den Link zum Jury Training für diese Kür:</p>
                <div>
                  <TextInput @value={{trainingLink}} readonly {{selectWhenFocus}} />
                  <IconButton
                    @icon="clipboard-text"
                    @importance="subtle"
                    @spacing="-1"
                    @label="Kopieren"
                    @push={{fn copyToClipboard trainingLink}}
                  />
                </div>
              </tab.Tab>
            {{/let}}
          {{/if}}
        </Tabs>
      </Popover>
    {{/let}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
const Toolbar: TOC<{
    Blocks: {
        default: [];
    };
}> = template_5697e73e57d14a6999a20becdd0b6290(`
  <p class={{styles.toolbar}}>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export { Toolbar };
