import { template as template_0f8c0205ce90441fb243e1e85f4eda2a } from "@ember/template-compiler";
import { Route } from 'ember-polaris-routing';
import CompatRoute from 'ember-polaris-routing/route/compat';
export class RoutinesRoute extends Route<object> {
    static{
        template_0f8c0205ce90441fb243e1e85f4eda2a(`{{outlet}}`, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
// @ts-expect-error some broken upstream types here
export default CompatRoute(RoutinesRoute);
