import { template as template_55a8fa7398ba4da482f87fc607e9d713 } from "@ember/template-compiler";
import { Icon } from '@hokulea/ember';
import styles from './spotify-player-warning.css';
export const SpotifyPlayerWarning = template_55a8fa7398ba4da482f87fc607e9d713(`
  <p>
    <Icon @icon="warning" @style="fill" class={{styles.warning}} />
    Bitte Spotify Player auswählen
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
