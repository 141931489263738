import { template as template_60d74c2a02f842c985b2f30eb68fe331 } from "@ember/template-compiler";
export const TricksStub = template_60d74c2a02f842c985b2f30eb68fe331(`
  <p>Tricks lassen sich sehr praktisch objektiv abbilden. Das hat
    <a href="https://gos.si/publikationen" target="_blank" rel="noopener noreferrer">Thomas Gossmann
      in seiner Diplomarbeit</a>
    gezeigt (S. 42ff).
  </p>
  <p>
    Die technische Grundlage hierfür muss jedoch noch geschaffen werden. Das wird noch dauern.
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
