import { template as template_5ec8aa0fbee74a95bc8249d8f65f521e } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { on } from '@ember/modifier';
import { action } from '@ember/object';
import { service } from '@ember/service';
import config from '@unidancing/app/config/environment';
import type RouterService from '@ember/routing/router-service';
export class LoginWithSpotify extends Component {
    @service
    router: RouterService;
    @action
    loginWithSpotify() {
        localStorage.setItem('redirectAfterLogin', this.router.currentURL as string);
    }
    static{
        template_5ec8aa0fbee74a95bc8249d8f65f521e(`
    <a href="{{config.workerHostURL}}/spotify/login" {{on "click" this.loginWithSpotify}}>
      Login with Spotify
    </a><br />
    (derzeit nur für ausgewählte Personen - wir arbeiten daran es für alle zugänglich zu machen).
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
