import { template as template_99736480470b46e4905fac057889df5a } from "@ember/template-compiler";
import { isReadyForPlayback } from '../abilities';
import { SpotifyPlayerWarning } from './spotify-player-warning';
export const MaybeSpotifyPlayerWarning = template_99736480470b46e4905fac057889df5a(`
  {{#unless (isReadyForPlayback)}}
    <SpotifyPlayerWarning />
  {{/unless}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
