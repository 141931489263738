import { template as template_d23e10533bdc4ca5a3657a717bd719e8 } from "@ember/template-compiler";
import { isAuthenticated } from '../abilities';
import { LoginWithSpotify } from './login-with-spotify';
import type { TOC } from '@ember/component/template-only';
const WithSpotify: TOC<{
    Blocks: {
        default: [];
    };
}> = template_d23e10533bdc4ca5a3657a717bd719e8(`
  {{#if (isAuthenticated)}}
    {{yield}}
  {{else}}
    <LoginWithSpotify />
  {{/if}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export { WithSpotify };
