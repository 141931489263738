import { template as template_0a84800ed6654c129b3bfb4d77b9946e } from "@ember/template-compiler";
import { ability } from 'ember-ability';
import { AudioPlayer, AudioService } from '../../audio';
import { SpotifyPlayer } from '../../spotify';
import styles from './player.css';
const isSpotifyPlayer = ability(({ service })=>()=>{
        return service(AudioService).player === AudioPlayer.Spotify;
    });
const Player = template_0a84800ed6654c129b3bfb4d77b9946e(`
  {{#if (isSpotifyPlayer)}}
    <div class={{styles.player}} data-player>
      <SpotifyPlayer />
    </div>
  {{/if}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export { Player };
