
import { importSync as i, macroCondition, getGlobalConfig } from '@embroider/macros';
let w = window;
let d = w.define;


i("./-embroider-implicit-modules.js");

d("@unidancing/app/app", function(){ return i("@unidancing/app/app.ts");});
d("@unidancing/app/application/controller", function(){ return i("@unidancing/app/application/controller.ts");});
d("@unidancing/app/application/route", function(){ return i("@unidancing/app/application/route.ts");});
d("@unidancing/app/application/template", function(){ return i("@unidancing/app/application/template.ts");});
d("@unidancing/app/config/environment", function(){ return i("@unidancing/app/config/environment.js");});
d("@unidancing/app/domain/core/arts/-components/art-tree", function(){ return i("@unidancing/app/domain/core/arts/-components/art-tree.ts");});
d("@unidancing/app/domain/core/arts/-components/details", function(){ return i("@unidancing/app/domain/core/arts/-components/details.ts");});
d("@unidancing/app/domain/core/arts/-components/index", function(){ return i("@unidancing/app/domain/core/arts/-components/index.ts");});
d("@unidancing/app/domain/core/arts/-resource", function(){ return i("@unidancing/app/domain/core/arts/-resource.ts");});
d("@unidancing/app/domain/core/arts/-routes/details", function(){ return i("@unidancing/app/domain/core/arts/-routes/details.ts");});
d("@unidancing/app/domain/core/arts/-routes/index", function(){ return i("@unidancing/app/domain/core/arts/-routes/index.ts");});
d("@unidancing/app/domain/core/arts/-routes/root", function(){ return i("@unidancing/app/domain/core/arts/-routes/root.ts");});
d("@unidancing/app/domain/core/arts/-types", function(){ return i("@unidancing/app/domain/core/arts/-types.ts");});
d("@unidancing/app/domain/core/arts/index", function(){ return i("@unidancing/app/domain/core/arts/index.ts");});
d("@unidancing/app/domain/core/assistants/assistants", function(){ return i("@unidancing/app/domain/core/assistants/assistants.ts");});
d("@unidancing/app/domain/core/assistants/components/assistant-factory", function(){ return i("@unidancing/app/domain/core/assistants/components/assistant-factory.ts");});
d("@unidancing/app/domain/core/assistants/dance-mix/dance-mix", function(){ return i("@unidancing/app/domain/core/assistants/dance-mix/dance-mix.ts");});
d("@unidancing/app/domain/core/assistants/index", function(){ return i("@unidancing/app/domain/core/assistants/index.ts");});
d("@unidancing/app/domain/core/assistants/looper/data", function(){ return i("@unidancing/app/domain/core/assistants/looper/data.ts");});
d("@unidancing/app/domain/core/assistants/looper/domain-objects", function(){ return i("@unidancing/app/domain/core/assistants/looper/domain-objects.ts");});
d("@unidancing/app/domain/core/assistants/looper/looper", function(){ return i("@unidancing/app/domain/core/assistants/looper/looper.ts");});
d("@unidancing/app/domain/core/assistants/resources", function(){ return i("@unidancing/app/domain/core/assistants/resources.ts");});
d("@unidancing/app/domain/core/assistants/routes/assistants", function(){ return i("@unidancing/app/domain/core/assistants/routes/assistants.ts");});
d("@unidancing/app/domain/core/choreography/-components/listing", function(){ return i("@unidancing/app/domain/core/choreography/-components/listing.ts");});
d("@unidancing/app/domain/core/choreography/-routes/index", function(){ return i("@unidancing/app/domain/core/choreography/-routes/index.ts");});
d("@unidancing/app/domain/core/choreography/-routes/not-todo-list", function(){ return i("@unidancing/app/domain/core/choreography/-routes/not-todo-list.ts");});
d("@unidancing/app/domain/core/choreography/-routes/root", function(){ return i("@unidancing/app/domain/core/choreography/-routes/root.ts");});
d("@unidancing/app/domain/core/choreography/-routes/unidance-writing", function(){ return i("@unidancing/app/domain/core/choreography/-routes/unidance-writing.ts");});
d("@unidancing/app/domain/core/choreography/awful-practices/components", function(){ return i("@unidancing/app/domain/core/choreography/awful-practices/components.ts");});
d("@unidancing/app/domain/core/choreography/awful-practices/domain", function(){ return i("@unidancing/app/domain/core/choreography/awful-practices/domain.ts");});
d("@unidancing/app/domain/core/choreography/awful-practices/resources", function(){ return i("@unidancing/app/domain/core/choreography/awful-practices/resources.ts");});
d("@unidancing/app/domain/core/choreography/index", function(){ return i("@unidancing/app/domain/core/choreography/index.ts");});
d("@unidancing/app/domain/core/choreography/routines/-utils", function(){ return i("@unidancing/app/domain/core/choreography/routines/-utils.ts");});
d("@unidancing/app/domain/core/choreography/routines/analysis/-components", function(){ return i("@unidancing/app/domain/core/choreography/routines/analysis/-components.ts");});
d("@unidancing/app/domain/core/choreography/routines/analysis/-resource", function(){ return i("@unidancing/app/domain/core/choreography/routines/analysis/-resource.ts");});
d("@unidancing/app/domain/core/choreography/routines/analysis/artistic/form", function(){ return i("@unidancing/app/domain/core/choreography/routines/analysis/artistic/form.ts");});
d("@unidancing/app/domain/core/choreography/routines/analysis/artistic/results", function(){ return i("@unidancing/app/domain/core/choreography/routines/analysis/artistic/results.ts");});
d("@unidancing/app/domain/core/choreography/routines/analysis/artistic/summary", function(){ return i("@unidancing/app/domain/core/choreography/routines/analysis/artistic/summary.ts");});
d("@unidancing/app/domain/core/choreography/routines/analysis/domain-objects", function(){ return i("@unidancing/app/domain/core/choreography/routines/analysis/domain-objects.ts");});
d("@unidancing/app/domain/core/choreography/routines/analysis/form", function(){ return i("@unidancing/app/domain/core/choreography/routines/analysis/form.ts");});
d("@unidancing/app/domain/core/choreography/routines/analysis/not-todo-list/domain", function(){ return i("@unidancing/app/domain/core/choreography/routines/analysis/not-todo-list/domain.ts");});
d("@unidancing/app/domain/core/choreography/routines/analysis/not-todo-list/form", function(){ return i("@unidancing/app/domain/core/choreography/routines/analysis/not-todo-list/form.ts");});
d("@unidancing/app/domain/core/choreography/routines/analysis/not-todo-list/results", function(){ return i("@unidancing/app/domain/core/choreography/routines/analysis/not-todo-list/results.ts");});
d("@unidancing/app/domain/core/choreography/routines/analysis/results", function(){ return i("@unidancing/app/domain/core/choreography/routines/analysis/results.ts");});
d("@unidancing/app/domain/core/choreography/routines/analysis/routines", function(){ return i("@unidancing/app/domain/core/choreography/routines/analysis/routines.ts");});
d("@unidancing/app/domain/core/choreography/routines/analysis/time-tracking/domain", function(){ return i("@unidancing/app/domain/core/choreography/routines/analysis/time-tracking/domain.ts");});
d("@unidancing/app/domain/core/choreography/routines/analysis/time-tracking/editor", function(){ return i("@unidancing/app/domain/core/choreography/routines/analysis/time-tracking/editor.ts");});
d("@unidancing/app/domain/core/choreography/routines/analysis/time-tracking/evaluation", function(){ return i("@unidancing/app/domain/core/choreography/routines/analysis/time-tracking/evaluation.ts");});
d("@unidancing/app/domain/core/choreography/routines/analysis/time-tracking/form", function(){ return i("@unidancing/app/domain/core/choreography/routines/analysis/time-tracking/form.ts");});
d("@unidancing/app/domain/core/choreography/routines/analysis/time-tracking/results", function(){ return i("@unidancing/app/domain/core/choreography/routines/analysis/time-tracking/results.ts");});
d("@unidancing/app/domain/core/choreography/routines/analysis/time-tracking/viewer", function(){ return i("@unidancing/app/domain/core/choreography/routines/analysis/time-tracking/viewer.ts");});
d("@unidancing/app/domain/core/choreography/routines/analysis/tricks/stub", function(){ return i("@unidancing/app/domain/core/choreography/routines/analysis/tricks/stub.ts");});
d("@unidancing/app/domain/core/choreography/routines/artistic/-components", function(){ return i("@unidancing/app/domain/core/choreography/routines/artistic/-components.ts");});
d("@unidancing/app/domain/core/choreography/routines/artistic/-utils", function(){ return i("@unidancing/app/domain/core/choreography/routines/artistic/-utils.ts");});
d("@unidancing/app/domain/core/choreography/routines/artistic/actions", function(){ return i("@unidancing/app/domain/core/choreography/routines/artistic/actions.ts");});
d("@unidancing/app/domain/core/choreography/routines/artistic/domain-objects", function(){ return i("@unidancing/app/domain/core/choreography/routines/artistic/domain-objects.ts");});
d("@unidancing/app/domain/core/choreography/routines/routes/details", function(){ return i("@unidancing/app/domain/core/choreography/routines/routes/details.ts");});
d("@unidancing/app/domain/core/choreography/routines/routes/index", function(){ return i("@unidancing/app/domain/core/choreography/routines/routes/index.ts");});
d("@unidancing/app/domain/core/choreography/routines/routes/results", function(){ return i("@unidancing/app/domain/core/choreography/routines/routes/results.ts");});
d("@unidancing/app/domain/core/choreography/routines/routes/root", function(){ return i("@unidancing/app/domain/core/choreography/routines/routes/root.ts");});
d("@unidancing/app/domain/core/choreography/routines/routes/test", function(){ return i("@unidancing/app/domain/core/choreography/routines/routes/test.ts");});
d("@unidancing/app/domain/core/choreography/routines/routes/training", function(){ return i("@unidancing/app/domain/core/choreography/routines/routes/training.ts");});
d("@unidancing/app/domain/core/choreography/routines/routes/training/results", function(){ return i("@unidancing/app/domain/core/choreography/routines/routes/training/results.ts");});
d("@unidancing/app/domain/core/choreography/routines/systems/actions", function(){ return i("@unidancing/app/domain/core/choreography/routines/systems/actions.ts");});
d("@unidancing/app/domain/core/choreography/routines/systems/data/iuf-performance-2019", function(){ return i("@unidancing/app/domain/core/choreography/routines/systems/data/iuf-performance-2019.ts");});
d("@unidancing/app/domain/core/choreography/routines/systems/domain-objects", function(){ return i("@unidancing/app/domain/core/choreography/routines/systems/domain-objects.ts");});
d("@unidancing/app/domain/core/choreography/routines/training/artistic/results", function(){ return i("@unidancing/app/domain/core/choreography/routines/training/artistic/results.ts");});
d("@unidancing/app/domain/core/choreography/routines/training/domain-objects", function(){ return i("@unidancing/app/domain/core/choreography/routines/training/domain-objects.ts");});
d("@unidancing/app/domain/core/choreography/routines/training/form", function(){ return i("@unidancing/app/domain/core/choreography/routines/training/form.ts");});
d("@unidancing/app/domain/core/choreography/routines/training/results", function(){ return i("@unidancing/app/domain/core/choreography/routines/training/results.ts");});
d("@unidancing/app/domain/core/courses/-components/details", function(){ return i("@unidancing/app/domain/core/courses/-components/details.ts");});
d("@unidancing/app/domain/core/courses/-components/index", function(){ return i("@unidancing/app/domain/core/courses/-components/index.ts");});
d("@unidancing/app/domain/core/courses/-resource", function(){ return i("@unidancing/app/domain/core/courses/-resource.ts");});
d("@unidancing/app/domain/core/courses/-routes/details", function(){ return i("@unidancing/app/domain/core/courses/-routes/details.ts");});
d("@unidancing/app/domain/core/courses/-routes/index", function(){ return i("@unidancing/app/domain/core/courses/-routes/index.ts");});
d("@unidancing/app/domain/core/courses/-routes/root", function(){ return i("@unidancing/app/domain/core/courses/-routes/root.ts");});
d("@unidancing/app/domain/core/courses/-types", function(){ return i("@unidancing/app/domain/core/courses/-types.ts");});
d("@unidancing/app/domain/core/courses/index", function(){ return i("@unidancing/app/domain/core/courses/index.ts");});
d("@unidancing/app/domain/core/exercises/-components/-formatters", function(){ return i("@unidancing/app/domain/core/exercises/-components/-formatters.ts");});
d("@unidancing/app/domain/core/exercises/-components/details", function(){ return i("@unidancing/app/domain/core/exercises/-components/details.ts");});
d("@unidancing/app/domain/core/exercises/-components/difficulty", function(){ return i("@unidancing/app/domain/core/exercises/-components/difficulty.ts");});
d("@unidancing/app/domain/core/exercises/-components/index", function(){ return i("@unidancing/app/domain/core/exercises/-components/index.ts");});
d("@unidancing/app/domain/core/exercises/-components/instruction", function(){ return i("@unidancing/app/domain/core/exercises/-components/instruction.ts");});
d("@unidancing/app/domain/core/exercises/-components/media", function(){ return i("@unidancing/app/domain/core/exercises/-components/media.ts");});
d("@unidancing/app/domain/core/exercises/-components/media/dance-mix", function(){ return i("@unidancing/app/domain/core/exercises/-components/media/dance-mix.ts");});
d("@unidancing/app/domain/core/exercises/-components/media/loop", function(){ return i("@unidancing/app/domain/core/exercises/-components/media/loop.ts");});
d("@unidancing/app/domain/core/exercises/-components/media/song", function(){ return i("@unidancing/app/domain/core/exercises/-components/media/song.ts");});
d("@unidancing/app/domain/core/exercises/-components/teaser", function(){ return i("@unidancing/app/domain/core/exercises/-components/teaser.ts");});
d("@unidancing/app/domain/core/exercises/-helpers", function(){ return i("@unidancing/app/domain/core/exercises/-helpers.ts");});
d("@unidancing/app/domain/core/exercises/-resource", function(){ return i("@unidancing/app/domain/core/exercises/-resource.ts");});
d("@unidancing/app/domain/core/exercises/-routes/details", function(){ return i("@unidancing/app/domain/core/exercises/-routes/details.ts");});
d("@unidancing/app/domain/core/exercises/-routes/index", function(){ return i("@unidancing/app/domain/core/exercises/-routes/index.ts");});
d("@unidancing/app/domain/core/exercises/-routes/root", function(){ return i("@unidancing/app/domain/core/exercises/-routes/root.ts");});
d("@unidancing/app/domain/core/exercises/domain-objects", function(){ return i("@unidancing/app/domain/core/exercises/domain-objects.ts");});
d("@unidancing/app/domain/core/exercises/index", function(){ return i("@unidancing/app/domain/core/exercises/index.ts");});
d("@unidancing/app/domain/core/games/bingo/bingo", function(){ return i("@unidancing/app/domain/core/games/bingo/bingo.ts");});
d("@unidancing/app/domain/core/games/components/game-factory", function(){ return i("@unidancing/app/domain/core/games/components/game-factory.ts");});
d("@unidancing/app/domain/core/games/dance-oh-mat/dance-oh-mat", function(){ return i("@unidancing/app/domain/core/games/dance-oh-mat/dance-oh-mat.ts");});
d("@unidancing/app/domain/core/games/games", function(){ return i("@unidancing/app/domain/core/games/games.ts");});
d("@unidancing/app/domain/core/games/index", function(){ return i("@unidancing/app/domain/core/games/index.ts");});
d("@unidancing/app/domain/core/games/resources", function(){ return i("@unidancing/app/domain/core/games/resources.ts");});
d("@unidancing/app/domain/core/games/routes/games", function(){ return i("@unidancing/app/domain/core/games/routes/games.ts");});
d("@unidancing/app/domain/core/home/index", function(){ return i("@unidancing/app/domain/core/home/index.ts");});
d("@unidancing/app/domain/core/home/routes/home", function(){ return i("@unidancing/app/domain/core/home/routes/home.ts");});
d("@unidancing/app/domain/core/moves/-components/details", function(){ return i("@unidancing/app/domain/core/moves/-components/details.ts");});
d("@unidancing/app/domain/core/moves/-components/index", function(){ return i("@unidancing/app/domain/core/moves/-components/index.ts");});
d("@unidancing/app/domain/core/moves/-components/teaser", function(){ return i("@unidancing/app/domain/core/moves/-components/teaser.ts");});
d("@unidancing/app/domain/core/moves/-resource", function(){ return i("@unidancing/app/domain/core/moves/-resource.ts");});
d("@unidancing/app/domain/core/moves/-routes/details", function(){ return i("@unidancing/app/domain/core/moves/-routes/details.ts");});
d("@unidancing/app/domain/core/moves/-routes/index", function(){ return i("@unidancing/app/domain/core/moves/-routes/index.ts");});
d("@unidancing/app/domain/core/moves/-routes/root", function(){ return i("@unidancing/app/domain/core/moves/-routes/root.ts");});
d("@unidancing/app/domain/core/moves/-types", function(){ return i("@unidancing/app/domain/core/moves/-types.ts");});
d("@unidancing/app/domain/core/moves/index", function(){ return i("@unidancing/app/domain/core/moves/index.ts");});
d("@unidancing/app/domain/core/skills/-resource", function(){ return i("@unidancing/app/domain/core/skills/-resource.ts");});
d("@unidancing/app/domain/core/skills/-routes/details", function(){ return i("@unidancing/app/domain/core/skills/-routes/details.ts");});
d("@unidancing/app/domain/core/skills/-routes/index", function(){ return i("@unidancing/app/domain/core/skills/-routes/index.ts");});
d("@unidancing/app/domain/core/skills/-types", function(){ return i("@unidancing/app/domain/core/skills/-types.ts");});
d("@unidancing/app/domain/core/skills/index", function(){ return i("@unidancing/app/domain/core/skills/index.ts");});
d("@unidancing/app/domain/core/training/-routes/athletic-profile", function(){ return i("@unidancing/app/domain/core/training/-routes/athletic-profile.ts");});
d("@unidancing/app/domain/core/training/-routes/control", function(){ return i("@unidancing/app/domain/core/training/-routes/control.ts");});
d("@unidancing/app/domain/core/training/-routes/diagnostics", function(){ return i("@unidancing/app/domain/core/training/-routes/diagnostics.ts");});
d("@unidancing/app/domain/core/training/-routes/diagnostics/body-language", function(){ return i("@unidancing/app/domain/core/training/-routes/diagnostics/body-language.ts");});
d("@unidancing/app/domain/core/training/-routes/diagnostics/index", function(){ return i("@unidancing/app/domain/core/training/-routes/diagnostics/index.ts");});
d("@unidancing/app/domain/core/training/-routes/diagnostics/time-tracking", function(){ return i("@unidancing/app/domain/core/training/-routes/diagnostics/time-tracking.ts");});
d("@unidancing/app/domain/core/training/-routes/index", function(){ return i("@unidancing/app/domain/core/training/-routes/index.ts");});
d("@unidancing/app/domain/core/training/-routes/planning", function(){ return i("@unidancing/app/domain/core/training/-routes/planning.ts");});
d("@unidancing/app/domain/core/training/-routes/planning/assistants", function(){ return i("@unidancing/app/domain/core/training/-routes/planning/assistants.ts");});
d("@unidancing/app/domain/core/training/-routes/planning/games", function(){ return i("@unidancing/app/domain/core/training/-routes/planning/games.ts");});
d("@unidancing/app/domain/core/training/-routes/planning/index", function(){ return i("@unidancing/app/domain/core/training/-routes/planning/index.ts");});
d("@unidancing/app/domain/core/training/-routes/planning/plans", function(){ return i("@unidancing/app/domain/core/training/-routes/planning/plans.ts");});
d("@unidancing/app/domain/core/training/-routes/planning/units", function(){ return i("@unidancing/app/domain/core/training/-routes/planning/units.ts");});
d("@unidancing/app/domain/core/training/-routes/root", function(){ return i("@unidancing/app/domain/core/training/-routes/root.ts");});
d("@unidancing/app/domain/core/training/index", function(){ return i("@unidancing/app/domain/core/training/index.ts");});
d("@unidancing/app/domain/supporting/audio/index", function(){ return i("@unidancing/app/domain/supporting/audio/index.ts");});
d("@unidancing/app/domain/supporting/audio/service", function(){ return i("@unidancing/app/domain/supporting/audio/service.ts");});
d("@unidancing/app/domain/supporting/audio/soundboard", function(){ return i("@unidancing/app/domain/supporting/audio/soundboard.ts");});
d("@unidancing/app/domain/supporting/audio/sounds/index", function(){ return i("@unidancing/app/domain/supporting/audio/sounds/index.ts");});
d("@unidancing/app/domain/supporting/spotify/abilities", function(){ return i("@unidancing/app/domain/supporting/spotify/abilities.ts");});
d("@unidancing/app/domain/supporting/spotify/client", function(){ return i("@unidancing/app/domain/supporting/spotify/client.ts");});
d("@unidancing/app/domain/supporting/spotify/components/login-with-spotify", function(){ return i("@unidancing/app/domain/supporting/spotify/components/login-with-spotify.ts");});
d("@unidancing/app/domain/supporting/spotify/components/maybe-spotify-player-warning", function(){ return i("@unidancing/app/domain/supporting/spotify/components/maybe-spotify-player-warning.ts");});
d("@unidancing/app/domain/supporting/spotify/components/play-track", function(){ return i("@unidancing/app/domain/supporting/spotify/components/play-track.ts");});
d("@unidancing/app/domain/supporting/spotify/components/player", function(){ return i("@unidancing/app/domain/supporting/spotify/components/player.ts");});
d("@unidancing/app/domain/supporting/spotify/components/playlist-chooser", function(){ return i("@unidancing/app/domain/supporting/spotify/components/playlist-chooser.ts");});
d("@unidancing/app/domain/supporting/spotify/components/spotify-play-button", function(){ return i("@unidancing/app/domain/supporting/spotify/components/spotify-play-button.ts");});
d("@unidancing/app/domain/supporting/spotify/components/spotify-player-warning", function(){ return i("@unidancing/app/domain/supporting/spotify/components/spotify-player-warning.ts");});
d("@unidancing/app/domain/supporting/spotify/components/with-spotify", function(){ return i("@unidancing/app/domain/supporting/spotify/components/with-spotify.ts");});
d("@unidancing/app/domain/supporting/spotify/domain-objects", function(){ return i("@unidancing/app/domain/supporting/spotify/domain-objects.ts");});
d("@unidancing/app/domain/supporting/spotify/helpers", function(){ return i("@unidancing/app/domain/supporting/spotify/helpers.ts");});
d("@unidancing/app/domain/supporting/spotify/index", function(){ return i("@unidancing/app/domain/supporting/spotify/index.ts");});
d("@unidancing/app/domain/supporting/spotify/machine", function(){ return i("@unidancing/app/domain/supporting/spotify/machine.ts");});
d("@unidancing/app/domain/supporting/spotify/resources/playlist", function(){ return i("@unidancing/app/domain/supporting/spotify/resources/playlist.ts");});
d("@unidancing/app/domain/supporting/spotify/resources/playlists", function(){ return i("@unidancing/app/domain/supporting/spotify/resources/playlists.ts");});
d("@unidancing/app/domain/supporting/spotify/resources/samples/warriors-sections", function(){ return i("@unidancing/app/domain/supporting/spotify/resources/samples/warriors-sections.json");});
d("@unidancing/app/domain/supporting/spotify/resources/samples/warriors", function(){ return i("@unidancing/app/domain/supporting/spotify/resources/samples/warriors.json");});
d("@unidancing/app/domain/supporting/spotify/resources/track", function(){ return i("@unidancing/app/domain/supporting/spotify/resources/track.ts");});
d("@unidancing/app/domain/supporting/spotify/routes/auth", function(){ return i("@unidancing/app/domain/supporting/spotify/routes/auth.ts");});
d("@unidancing/app/domain/supporting/spotify/service", function(){ return i("@unidancing/app/domain/supporting/spotify/service.ts");});
d("@unidancing/app/domain/supporting/spotify/tracks", function(){ return i("@unidancing/app/domain/supporting/spotify/tracks.ts");});
d("@unidancing/app/domain/supporting/tina/-client", function(){ return i("@unidancing/app/domain/supporting/tina/-client.ts");});
d("@unidancing/app/domain/supporting/tina/-components/tina-markdown", function(){ return i("@unidancing/app/domain/supporting/tina/-components/tina-markdown.ts");});
d("@unidancing/app/domain/supporting/tina/index", function(){ return i("@unidancing/app/domain/supporting/tina/index.ts");});
d("@unidancing/app/domain/supporting/ui/-components/card-section", function(){ return i("@unidancing/app/domain/supporting/ui/-components/card-section.ts");});
d("@unidancing/app/domain/supporting/ui/-components/card", function(){ return i("@unidancing/app/domain/supporting/ui/-components/card.ts");});
d("@unidancing/app/domain/supporting/ui/-components/dialog", function(){ return i("@unidancing/app/domain/supporting/ui/-components/dialog.ts");});
d("@unidancing/app/domain/supporting/ui/-components/features", function(){ return i("@unidancing/app/domain/supporting/ui/-components/features.ts");});
d("@unidancing/app/domain/supporting/ui/-components/icon", function(){ return i("@unidancing/app/domain/supporting/ui/-components/icon.ts");});
d("@unidancing/app/domain/supporting/ui/-components/note", function(){ return i("@unidancing/app/domain/supporting/ui/-components/note.ts");});
d("@unidancing/app/domain/supporting/ui/-components/player", function(){ return i("@unidancing/app/domain/supporting/ui/-components/player.ts");});
d("@unidancing/app/domain/supporting/ui/-components/section", function(){ return i("@unidancing/app/domain/supporting/ui/-components/section.ts");});
d("@unidancing/app/domain/supporting/ui/-components/tag", function(){ return i("@unidancing/app/domain/supporting/ui/-components/tag.ts");});
d("@unidancing/app/domain/supporting/ui/-components/video-player", function(){ return i("@unidancing/app/domain/supporting/ui/-components/video-player.ts");});
d("@unidancing/app/domain/supporting/ui/index", function(){ return i("@unidancing/app/domain/supporting/ui/index.ts");});
d("@unidancing/app/domain/supporting/utils/-data", function(){ return i("@unidancing/app/domain/supporting/utils/-data.ts");});
d("@unidancing/app/domain/supporting/utils/-helpers", function(){ return i("@unidancing/app/domain/supporting/utils/-helpers.ts");});
d("@unidancing/app/domain/supporting/utils/-serde", function(){ return i("@unidancing/app/domain/supporting/utils/-serde.ts");});
d("@unidancing/app/domain/supporting/utils/-temporal", function(){ return i("@unidancing/app/domain/supporting/utils/-temporal.ts");});
d("@unidancing/app/domain/supporting/utils/index", function(){ return i("@unidancing/app/domain/supporting/utils/index.ts");});
d("@unidancing/app/domain/supporting/youtube/components/youtube-player", function(){ return i("@unidancing/app/domain/supporting/youtube/components/youtube-player.ts");});
d("@unidancing/app/domain/supporting/youtube/index", function(){ return i("@unidancing/app/domain/supporting/youtube/index.ts");});
d("@unidancing/app/domain/supporting/youtube/service", function(){ return i("@unidancing/app/domain/supporting/youtube/service.ts");});
d("@unidancing/app/formats", function(){ return i("@unidancing/app/formats.js");});
d("@unidancing/app/router", function(){ return i("@unidancing/app/router.ts");});
d("@unidancing/app/initializers/glimmer-overrides", function(){ return i("@unidancing/app/initializers/glimmer-overrides.js");});
d("@unidancing/app/services/-portal", function(){ return i("@unidancing/app/services/-portal.js");});
d("@unidancing/app/services/link-manager", function(){ return i("@unidancing/app/services/link-manager.js");});
d("@unidancing/app/services/page-title", function(){ return i("@unidancing/app/services/page-title.js");});
d("@unidancing/app/services/theemo", function(){ return i("@unidancing/app/services/theemo.js");});
d("@unidancing/app/instance-initializers/clear-double-boot", function(){ return i("@unidancing/app/instance-initializers/clear-double-boot.js");});
d("@unidancing/app/locations/none", function(){ return i("@unidancing/app/locations/none.js");});
d("@unidancing/app/services/fastboot", function(){ return i("@unidancing/app/services/fastboot.js");});
d("@unidancing/app/component-managers/glimmer", function(){ return i("@unidancing/app/component-managers/glimmer.js");});
d("@unidancing/app/services/-ensure-registered", function(){ return i("@unidancing/app/services/-ensure-registered.js");});
d("@unidancing/app/services/intl", function(){ return i("@unidancing/app/services/intl.js");});
d("@unidancing/app/routes/arts", function(){ return i("@unidancing/app/routes/arts.ts");});
d("@unidancing/app/routes/arts/details", function(){ return i("@unidancing/app/routes/arts/details.ts");});
d("@unidancing/app/routes/arts/index", function(){ return i("@unidancing/app/routes/arts/index.ts");});
d("@unidancing/app/routes/assistants", function(){ return i("@unidancing/app/routes/assistants.ts");});
d("@unidancing/app/routes/auth/spotify", function(){ return i("@unidancing/app/routes/auth/spotify.ts");});
d("@unidancing/app/routes/games", function(){ return i("@unidancing/app/routes/games.ts");});
d("@unidancing/app/routes/index", function(){ return i("@unidancing/app/routes/index.ts");});

  if (macroCondition(getGlobalConfig().fastboot?.isRunning)) {
d("@unidancing/app/initializers/ajax", function(){ return i("@unidancing/app/initializers/ajax.js");});
d("@unidancing/app/initializers/error-handler", function(){ return i("@unidancing/app/initializers/error-handler.js");});
  }


w._embroiderRouteBundles_ = [
  {
    names: ["choreography","choreography.index","choreography.not-todo-list","choreography.routines","choreography.routines.details","choreography.routines.index","choreography.routines.results","choreography.routines.test","choreography.routines.test.index","choreography.routines.test_load","choreography.routines.training.index","choreography.routines.training.results","choreography.unidance-writing"],
    load: function() {
      return import("@unidancing/app/assets/_route_/choreography.js");
    }
  },
  {
    names: ["courses","courses.details","courses.index"],
    load: function() {
      return import("@unidancing/app/assets/_route_/courses.js");
    }
  },
  {
    names: ["exercises","exercises.details","exercises.index"],
    load: function() {
      return import("@unidancing/app/assets/_route_/exercises.js");
    }
  },
  {
    names: ["moves","moves.details","moves.index"],
    load: function() {
      return import("@unidancing/app/assets/_route_/moves.js");
    }
  },
  {
    names: ["skills.details","skills.index"],
    load: function() {
      return import("@unidancing/app/assets/_route_/skills.details.js");
    }
  },
  {
    names: ["training","training.athletic-profile","training.control","training.diagnostics","training.diagnostics.body-language","training.diagnostics.index","training.diagnostics.time-tracking","training.index","training.planning","training.planning.assistants","training.planning.games","training.planning.index","training.planning.plans","training.planning.units"],
    load: function() {
      return import("@unidancing/app/assets/_route_/training.js");
    }
  },
]



if (typeof FastBoot === 'undefined') {
  if (!runningTests) {
    require('@unidancing/app/app')['default'].create({});
  }
}


