import { template as template_0d3ac1d7380a45c0b1d07ad848404e8b } from "@ember/template-compiler";
import { on } from '@ember/modifier';
import { LinkTo } from '@ember/routing';
import { link } from 'ember-link';
import { pageTitle } from 'ember-page-title';
import { Route } from 'ember-polaris-routing';
import CompatRoute from 'ember-polaris-routing/route/compat';
import { Page } from '@hokulea/ember';
import { Game } from '../../../games';
export class TrainingPlanningGamesRoute extends Route<object> {
    static{
        template_0d3ac1d7380a45c0b1d07ad848404e8b(`
    {{pageTitle "Spiele"}}

    <Page @title="Spiele">
      <ul>
        {{#let (link "games" Game.Bingo) as |bingoLink|}}
          <li>
            <a href={{bingoLink.url}} {{on "click" bingoLink.transitionTo}}>Bingo</a><br />
            Spielerische Sensibilisierung für Aspekte, die man besser
            <LinkTo @route="choreography.not-todo-list"><i>nicht macht</i> in einer Kür</LinkTo>.
          </li>
        {{/let}}
      </ul>
    </Page>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
// @ts-expect-error some broken upstream types here
export default CompatRoute(TrainingPlanningGamesRoute);
