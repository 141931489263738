import { template as template_a9692381006c45919a6e4c657d8838b4 } from "@ember/template-compiler";
import { YoutubePlayer } from '../../../../supporting/youtube';
import { scoreArtistic } from '../artistic/actions';
import { loadSystem, loadSystemDescriptor } from '../systems/actions';
import { ArtisticTrainingResults } from './artistic/results';
import type { WireArtisticResults } from '../artistic/domain-objects';
import type { TrainingResult } from './domain-objects';
import type { TOC } from '@ember/component/template-only';
interface TrainingResultsSignature {
    Args: {
        data: TrainingResult;
    };
}
function score(artistic: WireArtisticResults) {
    const system = loadSystem(loadSystemDescriptor(artistic.name));
    return scoreArtistic(system, artistic);
}
const TrainingResults: TOC<TrainingResultsSignature> = template_a9692381006c45919a6e4c657d8838b4(`
  <YoutubePlayer @url={{@data.video}} />

  <ArtisticTrainingResults @data={{score @data.result}} @reference={{score @data.reference}} />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default TrainingResults;
