import { template as template_cbe956bd242e4d62b6785e2c3db64f7d } from "@ember/template-compiler";
import { cached } from '@glimmer/tracking';
import { service } from '@ember/service';
import { pageTitle } from 'ember-page-title';
import { Route } from 'ember-polaris-routing';
import CompatRoute from 'ember-polaris-routing/route/compat';
import { use } from 'ember-resources';
import Task from 'ember-tasks';
import { CourseDetails } from '../-components';
import { findCourse } from '../-resource';
import type FastbootService from 'ember-cli-fastboot/services/fastboot';
export class CourseDetailsRoute extends Route<{
    id: string;
}> {
    @service
    fastboot: FastbootService;
    @cached
    get load() {
        const promise = use(this, findCourse(this.params.id)).current;
        if (this.fastboot.isFastBoot) {
            this.fastboot.deferRendering(promise);
        }
        return Task.promise(promise);
    }
    static{
        template_cbe956bd242e4d62b6785e2c3db64f7d(`
    {{#let this.load as |r|}}
      {{#if r.resolved}}
        {{pageTitle r.value.title}}

        <CourseDetails @course={{r.value}} />
      {{/if}}
    {{/let}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
export default CompatRoute(CourseDetailsRoute);
