import { template as template_f9449e5f4ad24446b1efd6c8fcf2a9ff } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { TimeTrackingEvaluation } from './evaluation';
import { TimelineViewer } from './viewer';
import type { YoutubePlayerAPI } from '../../../../../supporting/youtube';
import type { TimeAnalysis } from './domain';
interface TimeTrackingResultsSignature {
    Args: {
        active: boolean;
        data: TimeAnalysis;
        playerApi?: YoutubePlayerAPI;
    };
}
export class TimeTrackingResults extends Component<TimeTrackingResultsSignature> {
    player: YoutubePlayerAPI;
    static{
        template_f9449e5f4ad24446b1efd6c8fcf2a9ff(`
    <TimelineViewer @active={{@active}} @playerApi={{@playerApi}} @data={{@data}} />

    <h3>Auswertung</h3>
    <TimeTrackingEvaluation @data={{@data}} />
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
